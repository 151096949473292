<template>
	<div class="page">
		<div class="orderState">
			<div v-if="orderState==='success'">
				<img  class="orderStateImg" src="../../assets/img/paySuccess.png">
				<div class="orderStateText">支付结果：成功</div>
			</div>
			<div v-if="orderState==='fail'">
				<img  class="orderStateImg" src="../../assets/img/orderCancel.png">
				<div class="orderStateText">支付结果：失败</div>
			</div>
			<div v-if="orderState==='ing'">
				<img  class="orderStateImg" src="../../assets/img/orderOvertime.png">
				<div class="orderStateText">正在获取支付结果...</div>
			</div>
		</div>
		<div style="background-color: #fff;padding:.32rem;">
			<div class="shopName">花生宠物</div>
			<div class="goodsLine">
				<img class="goodsImg" v-if="!$public.isNull(goodsDetail.bannerList)" :src="goodsDetail.bannerList[0].url">
				<img class="goodsImg" v-else src="../../assets/img/noPicture.png">
				<div class="goodsMsg">
					<div class="goodsName">
						<span>{{goodsDetail.insideName}}</span>
						<span>¥{{goodsDetail.petLevel.price}}</span>
					</div>
					<div class="goodsSku">
						<div class="color">{{goodsDetail.petColor}}</div>
						<div class="color">芯片：{{goodsDetail.epromNo}}</div>
					</div>
				</div>
			</div>
			<div class="orderMsgLine">
				<div class="label">订单号</div>
				<div class="value">{{orderDetail.no}}</div>
			</div>
			<div class="orderMsgLine">
				<div class="label">客户手机</div>
				<div class="value">{{orderDetail.user_info.user_mobile.substr(0,3)}}****{{orderDetail.user_info.user_mobile.substr(7,10)}}</div>
			</div>
			<!-- 订金订单 -->
			<div v-if="orderDetail.order_type_id===10">
				<div class="orderMsgLine">
					<div class="label">订金金额</div>
					<div class="value">¥ {{allDingjin}}</div>
				</div>
			</div>
			<div v-if="orderDetail.order_type_id===8">
				<div class="orderMsgLine">
					<div class="label">订金抵扣</div>
					<div class="value">¥ {{useDingjin}}</div>
				</div>
				<div class="orderMsgLine">
					<div class="label">商品总额</div>
					<div class="value">¥ {{orderDetail.order_item_list[0].price}}</div>
				</div>
				<div class="orderMsgLine">
					<div class="label">活动</div>
					<div class="value">{{orderDetail.order_item_list[0].activity_info.activity_name}}</div>
				</div>
				<div class="orderMsgLine">
					<div class="label">优惠金额</div>
					<div class="value">¥ {{$public.jian(goodsDetail.petLevel.price, orderDetail.total_price)}}</div>
				</div>
			</div>
			<div class="orderMsgLine" style="height: 1.6rem;">
				<div class="label">备注</div>
				<div class="value">{{orderDetail.remark}}</div>
			</div>
			<div class="totalLine">
				本次支付金额：<span style="font-size: .26rem;color:#ff5701;">¥ {{$public.jian(orderDetail.total_price,useDingjin)||0}}</span>
			</div>
		</div>
		<div style="height: 2rem;"></div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				hour:0,
				minute:0,
				second:0,
				timer:null,
				
				orderState:'ing',
				orderDetail:{
					order_item_list:[{
						activity_info:{},
						order_item_goods_list:[{
							custom_code:''
						}]
					}],
					user_info:{
						user_mobile:''
					}
				},
				goodsDetail:{
					petLevel:{
						price:0,
					}
				},
				allDingjin:0,
				useDingjin:0,
				
				uId:'',
				orderNum:'',
				remark:'',
			}
		},
		mounted(){
			this.uId = this.$public.queryString('uId')
			this.orderNum = this.$public.queryString('orderNo')
			
			this.getOrderDetail();
			
		},
		methods:{
			getOrderDetail(){
				let orderNum = this.orderNum
				let params = {
					id: orderNum,
				}
				this.$http.get(process.env.VUE_APP_Url_order + '/api/OffLineOrder/GetShowDJ', params).then(res => {
					if (res.code == 0) {
						if(this.$public.isNull(res.data.order_item_list[0].activity_info)){
							res.data.order_item_list[0].activity_info = []
						}
						this.orderDetail = res.data;
						this.getGoodsDetail(this.orderDetail.order_item_list[0].goods_id)
						this.useDingjin = 0;
						if(this.orderDetail.order_type_id===10){
							this.getAllDingjin(this.orderDetail.user_info.user_id, this.orderDetail.order_item_list[0].goods_id)
						}else if(this.orderDetail.order_type_id===8){
							if(this.orderDetail.state===3){
								this.useDingjin = this.$public.getMatchingItem(this.orderDetail.pay_info.pay_list,'pay_type',14).money||0
							}else{
								if(this.$public.isNull(this.orderDetail.djList)){
									this.orderDetail.djList = []
								}
								for(let i=0;i<this.orderDetail.djList.length;i++){
									this.useDingjin += this.orderDetail.djList[i].total_price;
								}
							}
						}
						if(this.orderDetail.state===3){
							this.orderState = 'success'
						}else{
							this.orderState = 'fail'
						}
					}
				})
			},
			getGoodsDetail(petId){
				let params = {
					petId:petId,
				}
				this.$http.get(process.env.VUE_APP_Url_baseData + '/api/product/pet/detail/info', params).then(res => {
					if (res.code == 0) {
						let d = res.data
						this.goodsDetail = d;
					}
				})
			},
			getAllDingjin(user_id,pet_id){
				let params = {
					user_id:user_id,
					pet_id:pet_id,
				}
				this.$http.get(process.env.VUE_APP_Url_order + '/api/OffLineOrder/GetPetDJMoney', params).then(res => {
					if (res.code == 0) {
						this.allDingjin = res.data
					}
				})
			},
		},
		beforeDestroy(){
			clearInterval(this.timer)
		},
	};
</script>
<style lang="scss" scoped>
.page{
	width:100%;height: 100%;box-sizing: border-box;overflow: auto;
	.timeLine{
		width:100%;height: 2.6rem;background-color: #f9bd05;
		.time{
			font-size: .56rem;font-weight: bold;color:#333;padding-top:.7rem;
		}
		.desc{
			display: flex;justify-content: center;padding-top:.2rem;
			.descImg{
				width:.32rem;height: .32rem;margin-right: .16rem;
			}
			.descText{
				font-size: .28rem;color:#333;
			}
		}
	}
	.orderState{
		width:100%;height: 2.6rem;
		.orderStateImg{
			width:1.12rem;height: 1.12rem;padding-top:.6rem;
		}
		.orderStateText{
			font-size: .28rem;color:#666;
		}
	}
	.shopName{
		font-size: .28rem;color:#333;display: flex;padding:0 0 .28rem 0;
	}
	.goodsLine{
		display: flex;border-bottom:0.02rem solid #f0f0f2;padding-bottom:.2rem;
		.goodsImg{
			width:1.56rem;height: 1.56rem;border-radius: .2rem;margin-right: .24rem;
		}
		.goodsMsg{
			width:5rem;
			.goodsName{
				font-size: .26rem;color:#333;display: flex;justify-content: space-between;padding:.1rem 0 .1rem;
			}
			.goodsSku{
				background-color: #f9f9fb;border-radius: .08rem;color:#999;width:3.2rem;text-align: left;line-height: .4rem;padding:.1rem .16rem;font-size: .24rem;
			}
		}
	}
	.orderMsgLine{
		display: flex;justify-content: space-between;font-size: .26rem;color:#333;border-bottom:0.02rem solid #f0f0f2;line-height: 1rem;
		.label{
			
		}
		.value{
			
		}
	}
	.totalLine{
		font-size: .26rem;color:#333;text-align: right;padding-top:.3rem;font-weight: bold;
	}
	
}
</style>
